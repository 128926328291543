/*
 * 업무구분: 리크루팅
 * 화 면 명: MSPRC200M
 * 화면 설명: 전체후보자
 * 접근 권한: 일반사용자, 지점장, 지역단스텝, 리크루팅본사스텝 (U000, F110, F102, R100)
 * 작 성 일: 2022.05.30
 * 작 성 자: sh.park2022
 */

<template>
<!-- action-type="search"  -->
  <ur-page-container title="전체후보자" :show-title="true" type="subpage" :action-type="actionType" @on-header-left-click="fn_HistoryBack">
    <template #frame-header-fixed>
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="sum">총 {{ whlCndtrList.length }}명</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <ur-box-container direction="column" alignV="start">
      <ur-box-container v-if="!isNoData" alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list center">
        <mo-list-item expandable ref="refCndtrItem" v-for="item in whlCndtrList" :key="item.title">
          <div class="list-item__contents">
            <div class="list-item__contents__title">
              <span class="name txtSkip">{{ item.cndtrNm }}</span><!-- 후보자명 -->
            </div>

            <div class="list-item__contents__info">
              <div class="txt_box">
                <span>{{ $bizUtil.dateDashFormat(item.dob) }}</span><em>|</em><!-- 생년월일 -->
                <span>{{ item.sxcdNm === '남자'? '남' : '여' }}</span><em>|</em><!-- 성별 -->
                <span>{{ item.qlfYn }}</span><!-- 적격여부 -->
              </div>
            </div>
            <div class="list-item__contents__info">
              <div class="txt_box">
                <span>{{ item.fofNm }}</span><em>|</em><!-- 지점 -->
                <span class="date">삭제예정일 <strong class="fwsolid">{{ $bizUtil.dateDashFormat(item.deltParngYmd) }}</strong></span>
              </div>
            </div>
          </div>
          <template v-slot:expand>
            <div class="list-item__contents">
              <div class="list-item__contents__info">
                <span class="info">후보자등록일</span>
                <span class="info">{{ $bizUtil.dateDashFormat(item.cndtrRegYmd) }}</span>
              </div>
              <div class="list-item__contents__info">
                <span class="info">리크루팅</span>
                <span>{{ fn_GetRcce(item.recrCnsntYn, item.recrCnsntEnd) }}</span><!-- 리크루팅 -->
              </div>
              <div class="list-item__contents__info">
                <span class="info">경력등록기한</span>
                <span class="info">{{ $bizUtil.dateDashFormat(item.carrRegTmlmt) }}</span>
              </div>
              <div class="list-item__contents__info">
                <span class="info">추천FC(사번)</span>
                <span class="info fullHeight">{{ item.recmFcNm }}({{ item.recmFcEno }})</span>
              </div>
            </div>
          </template>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역 ::start  -->
      <ur-box-container v-else alignV="start" componentid="ur_box_container_002" direction="column" class="ns-contract-list no-data">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__info">
              <span>데이터가 존재하지 않습니다.</span>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
      <!-- NoData 영역 ::end  -->
    </ur-box-container>
  </ur-page-container>
</template>
<script>
import Msg from '@/systems/webkit/msg/msg'
import Screen from '~systems/mixin/screen'

import MSPRC201P from '@/ui/rc/MSPRC201P.vue'

export default {
  name: 'MSPRC200M',
  screenId: 'MSPRC200M',
  mixins: [Screen],
  components: {
    MSPRC201P
  },
  data() {
    return {
      title: '',
      userInfo: {}, // 로그인 사번 정보

      whlCndtrList: [],  // 후보자 리스트 데이터
      isLoading: false,  // 통신 조회 여부
      isNoData: false,   // 데이터 없음

      actionType: {},
      pOrgData200M: {}, // 최근 조직 조회 데이터
      isSearched: false, // 조회이력 저장 변수
      popup201: {},  // MSPRC201P 팝업
      sortValu: '1'  // 정렬기준
    }
  },
  /************************************************************************************************
  * Life Cycle 함수 정의 영역
  ************************************************************************************************/
  created() {
    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
    const lv_Vm = this
    // 바텀 메뉴 보임 처리
    lv_Vm.$BottomManager.fn_SetBottomVisible(true)

    this.actionType = { actionComponent:{
        template: '<span><mo-icon class="add-cust" @click="moveAddRc">add_user</mo-icon><mo-icon @click="openPop">search</mo-icon></span>',
        methods: {
          openPop() {
            lv_Vm.fn_OpenPop()
          },
          moveAddRc() {
            this.$router.push({name:"MSPRC100M"}).catch(()=>{})
          }
        }
      }}

    this.fn_Init()
  },
  mounted() {
    /** LOG 적재 Util **/
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },
  beforeDestroy() {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_HistoryBack)
  },
  /************************************************************************************************
   * 사용자 함수 정의 영역
   ************************************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 진입시, 최초실행 함수
     ******************************************************************************/
    fn_Init() {
      let firstPData = {}

      this.userInfo = this.getStore('userInfo').getters.getUserInfo
      // console.log('userInfo', this.userInfo)

      firstPData = this.fn_SetFirstCnsltParam()
      this.fn_SlectCnsltResultRC(firstPData)
    },
    /******************************************************************************
     * Function명 : fn_HistoryBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack () {
      if (this.isSearched) {
        // 조회를 한번이라도 한 경우 전체 검색

        // 초기화
        this.isSearched = false // 검색이력 초기화
        this.fn_Init()
      } else {
        // 조회를 하지 않았으면 뒤로가기
        this.$router.go(-1)
      }
    },
    /******************************************************************************
     * Function명 : fn_SetFirstCnsltParam
     * 설명       : 화면 진입시, 초기 데이터 조회 파라미터 셋팅
     *              일반사용자, FC일경우, 본인 사번으로 셋팅후 조회
     ******************************************************************************/
    fn_SetFirstCnsltParam() {
      let cnsltNo = ''

      if (this.userInfo.isFc === 'Y') {
        cnsltNo = this.userInfo.cnsltNo
      }

      let firstPData = {
                    cnslt:  cnsltNo,
                    dofOrg: this.userInfo.onpstDofNo,
                    fofOrg: this.userInfo.onpstFofOrgNo,
                    hofOrg: this.userInfo.onpstHofOrgNo
                  }
      return firstPData
    },
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultRC
     * 설명       : 화면 진입시, 전역변수에 셋팅 (userInfo의 조직 데이터 셋팅)
     ******************************************************************************/
    fn_SlectCnsltResultRC(pData) {
      pData = {
                cndtrNm : '',               // 후보자명
                cnsltEno: pData.cnslt,      // 컨설턴트 사번
                hofOrgCd: pData.hofOrg,     // 선택사업부
                dofOrgCd: pData.dofOrg,     // 선택지역단
                fofOrgCd: pData.fofOrg,     // 선택지점
                sortValu: '1'               // 정렬기준
              }

      this.fn_SelListAllCndtr(pData)
    },
    /**************************************************************************************
     * Function명 : fn_SelListAllCndtr
     * 설명       : 후보자 리스트 조회
     * OrgData: 상세조회(MSPRC201P)에서 입력한 값 / 화면 진입시 UserInfo의 조직으로 셋팅
     **************************************************************************************/
    fn_SelListAllCndtr(OrgData) {
      const lv_Vm = this

      // 중복 호출 방지
      if (this.isLoading) return
 
      if(OrgData.fofOrgCd === '0' || this.$bizUtil.isEmpty(OrgData.fofOrgCd)) {
        OrgData.fofOrgCd = this.userInfo.onpstFofOrgNo
      }
      const trnstId = 'txTSSRC20S1'
      const auth = 'S'
      let pParams = {
        cndtrNm   : OrgData.cndtrNm,  // 후보자명
        cnsltEno  : OrgData.cnsltEno, // 컨설턴트 사번
        hqOrgCd   : OrgData.hofOrgCd, // 선택사업부
        dofOrgCd  : OrgData.dofOrgCd, // 선택지역단
        fofOrgCd  : OrgData.fofOrgCd, // 선택지점
        stdilStnd : OrgData.sortValu  // 정렬기준
      }

      this.post(lv_Vm, pParams, trnstId, auth)
        .then(function (response) {

          lv_Vm.whlCndtrList = []
          // 서버 데이터 가져오기
          if (!_.isEmpty(response.body) && !_.isEmpty(response.body.whlCndtrList)) {
            let responseList = response.body.whlCndtrList

            responseList.forEach((item) => {
              lv_Vm.whlCndtrList.push(item)
            })
            lv_Vm.isNoData = false
          } else {
            lv_Vm.isNoData = true // 데이터가 존재하지 않습니다
          }
        })
        .catch(function (error) {
          window.vue.error(error)
          lv_Vm.isNoData = true
        })
    },
    /******************************************************************************
     * Function명  : fn_GetRcce
     * 설명        : 리크루팅 끝나기 남은 일수
     * recrCnsntYn : 리크루팅 동의 여부
     * recrCnsntEnd: recrCnsntEnd
     ******************************************************************************/
    fn_GetRcce(recrCnsntYn, recrCnsntEnd) {
      let rtnValu = ''

      // 리크루팅 남은 일자가 100일 이상이라면, Y D-99+
      // 아니라면, 예시) Y D-55
      if (recrCnsntEnd  >= 0 && recrCnsntEnd  <100) {
        rtnValu = recrCnsntYn + " D-" + recrCnsntEnd
      } else if (recrCnsntEnd >= 100) {
        rtnValu = recrCnsntYn + " D-99+"
      } else {
        rtnValu = recrCnsntYn
      }

      return rtnValu
    },
    /******************************************************************************
     * Function명 : fn_OpenPop
     * 설명       : 상세조회 팝업 호출
     * listeners: function 등록후, 팝업에서 emit시 실행됩니다.
     ******************************************************************************/
    fn_OpenPop() {
      const lv_Vm = this

      this.popup201 = this.$bottomModal.open(MSPRC201P,{
        properties: {
          title: '상세조회', // 팝업의 타이틀 셋팅
          pOrgData200M: this.pOrgData200M, // 최근 조직 조회 데이터
          pSortValu: this.sortValu
        },
        listeners: {
          // 팝업 닫히면서 실행되는 함수
          onPopupSearch: (pData) => {
            this.$bottomModal.close(lv_Vm.popup201)


            // 마지막 조직 조회 데이터 셋팅
            // 마지막 조회 정렬로 셋팅(default: 최신순)
            if (pData.OrgData.dofOrgCd !== '0' && pData.OrgData.fofOrgCd !== '0') {
              // 조회이력 저장
              lv_Vm.isSearched = true
              
              lv_Vm.pOrgData200M = pData.OrgData.pOrgData
              lv_Vm.sortValu = pData.OrgData.sortValu
            }

			// 모든 아코디언 닫힘 처리
            for (let i=0; i<lv_Vm.whlCndtrList.length; i++) {
              lv_Vm.$refs['refCndtrItem'][i].collapse()
            }

            lv_Vm.fn_SelListAllCndtr(pData.OrgData)
          }
        }
      })
    }
  }
}
</script>
